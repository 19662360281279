import { createRouter,createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/product',
    name: 'product',
    component: () => import('../views/ProductView.vue')
  },
  {
    path: '/case',
    name: 'case',
    component: () => import('../views/CaseView.vue')
  },
  {
    path: '/factory',
    name: 'factory',
    component: () => import('../views/FactoryView.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('../views/NewsView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/ContactView.vue')
  },
  {
    path: '/hor',
    name: 'hor',
    component: () => import('../views/HorView.vue')
  },
  {
    path: '/compcultrue',
    name: 'compcultrue',
    component: () => import('../views/CompanyCulture.vue')
  },
  {
    path: '/compeasy',
    name: 'compeasy',
    component: () => import('../views/CompanyEasy.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  // mode:'hash',
  routes,
  scrollBehavior () {
    return { top: 0 }
  }
})

export default router
