 
<template>
  <footer>
    <div class="container">
      <a-row>
        <a-col :span="4">
        <img src="../assets/logomini.png" />
      </a-col>
        <a-col :span="4">
          <h3>公司信息</h3>
          <p>公司名称：重庆华瑞铝业有限公司</p>
          <p>地址：重庆市綦江区飞腾路8号</p>
          <p>电话：138 8385 6733</p>
        </a-col>
        <a-col :span="4"></a-col>
        <a-col :span="4">
          <h3>产品服务</h3>
          <ul>
            <li><router-link to="/product">铝单板</router-link></li>
            <li><router-link to="/product">仿石材铝单板</router-link></li>
            <li><router-link to="/product">仿木纹铝单板</router-link></li>
            <li><router-link to="/product">雕花铝单板</router-link></li>
            <li><router-link to="/product">冲孔铝单板</router-link></li>
            <li><router-link to="/product">像形冲孔铝单板</router-link></li>
            <li><router-link to="/product">瓦楞板</router-link></li>
            <li><router-link to="/product">龙鳞冲孔铝单板</router-link></li>
            <li><router-link to="/product">铝方通</router-link></li>
          </ul>
        </a-col>
        <a-col :span="4"></a-col>
        <a-col :span="4">
          <h3>关注我们</h3>
          <ul>
            <li>
              <a-popover title="二维码">
                <template #content>
                  <img src="../assets/qr.jpg" />
                </template>
                <a>微信公众号</a>
              </a-popover>
            </li>
            <li>
              <a-popover title="二维码">
                <template #content>
                  <p>暂无</p>
                </template>
                <a>微博</a>
              </a-popover>
            </li>
            <li>
              <a-popover title="二维码">
                <template #content>
                  <p>暂无</p>
                </template>
                <a>抖音</a>
              </a-popover>
            </li>
          </ul>
        </a-col>
      </a-row>
      <hr />
      <span>版权所有：©重庆华瑞铝业有限公司</span><a href="https://beian.miit.gov.cn/">&nbsp;&nbsp;渝ICP备2023008946号</a><span>&nbsp;&nbsp;<img sizes="100px" src="../assets/图片_20240520195949.png">备案号:<a href="http://www.beian.miit.gov.cn/" rel="noreferrer" target="_blank">渝公网安备50022202000495</a></span>
      
    </div>
  </footer>
</template>

<style scoped>
footer {
  background-color: #f5f5f5;
}

footer h3 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

footer p {
  font-size: 14px;
  margin-bottom: 10px;
}

footer ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

footer ul li {
  margin-bottom: 10px;
}

footer ul li a {
  color: #333;
  font-size: 14px;
  text-decoration: none;
}

footer ul li a:hover {
  color: #007bff;
}
</style>

