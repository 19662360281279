<template>
  <div>
    <img alt="Vue logo" src="../assets/logo.png">
    <nav>
      <a-menu v-modle:Keys="current" mode="horizontal">
      <a-menu-item key="home">
        <template #icon>
          <home-outlined />
        </template>
        <router-link to="/">首页</router-link>
      </a-menu-item>
      <a-menu-item key="about">
        <a-sub-menu key="about">
          <template #icon>
            <appstore-outlined />
          </template>
          <template #title><router-link to="/about">关于我们</router-link></template>
          <a-menu-item-group>
            <a-menu-item key="setting:1"><router-link to="/compcultrue">公司简介</router-link></a-menu-item>
            <a-menu-item key="setting:2"><router-link to="/compeasy">企业文化</router-link></a-menu-item>
            <a-menu-item key="setting:3"><router-link to="/hor">荣誉墙</router-link></a-menu-item>
          </a-menu-item-group>
        </a-sub-menu> 
      </a-menu-item>

      <a-menu-item key="product">
        <template #icon>
          <appstore-outlined />
        </template>
        <router-link to="/product"> 产品中心</router-link>
      </a-menu-item>
      <a-menu-item key="case">
        <template #icon>
          <appstore-outlined />
        </template>
        <router-link to="/case">经典案例</router-link>
      </a-menu-item>
      <a-menu-item key="factory">
        <template #icon>
          <appstore-outlined />
        </template>
        <router-link to="/factory">工厂展示</router-link>
      </a-menu-item>
      <a-menu-item key="contact">
        <template #icon>
          <phone-outlined />
        </template>
        <router-link to="/contact">联系我们</router-link>
      </a-menu-item>
     </a-menu>
    </nav>
  </div>
</template>

<script>
import { defineComponent,  } from 'vue';
// import { defineComponent, ref } from 'vue';

import {  AppstoreOutlined,HomeOutlined,PhoneOutlined } from '@ant-design/icons-vue';
export default defineComponent({

  components: {
    // MailOutlined,
    AppstoreOutlined,
    HomeOutlined,
    PhoneOutlined,
  },
  data(){
    // const current = ref(['home'])
    return{
      current:'home',
    }
  }
})
</script>

<style scoped>
nav {
  padding: 30px;
}
img{
  float: left;
  margin-left: 30px;
}
</style>