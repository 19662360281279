<template>
  <div class="home">
    <a-carousel arrows effect="fade" :autoplay="true">
    <template #prevArrow>
      <div class="custom-slick-arrow" style="left: 10px; z-index: 1">
        <left-circle-outlined />
      </div>
    </template>
    <template #nextArrow>
      <div class="custom-slick-arrow" style="right: 10px">
        <right-circle-outlined />
      </div>
    </template>
    <div class="company">
      <img alt="Vue logo" src="../assets/company.png">
    </div>
    <div>
      <img alt="Vue logo" src="../assets/home2.png">
    </div>
  </a-carousel>
  <div style="margin-top: 10px;">
    <a-row>
      <!-- 董事长寄语 -->
      <a-col :span="12">
        <div style="margin-left: 10%;">
          <img src="../assets/boss.jpg" />
          <h1>董事长寄语</h1>
          <h2>chairman message</h2>
          <a-divider />
          <h3>创造力是我们前进的原动力,</h3>
          <h3>帮助我们实现从概念构思到项目的实施。</h3>
          <h3>不断的完善自我、精益求精、锐意创新!</h3>
          <h3>用我们专业的水准实现客户的要求。</h3>
          <h5>Creativity is what keeps us going</h5>
          <h5>It helps us realize the implementation trom concept conception to projectimpleimentation.</h5>
          <h5>Continuous sclfimprovemment,cxcellencc,innovation!</h5>
          <h5>With our professionallevel to achieve eustomer requirements.</h5>
        </div>
       
      </a-col>
      <a-col :span="12" style="background-color: #2b378b;">
        <div style="margin-left: 10%; margin-top: 10%;">
          <h1 style="color:white;">企业简介</h1>
          <h2 style="color:white;">ENTERPRISE PROFILE</h2>
          <hr />
          <br />
          <br />
          <p style="text-indent: 2em;">
重庆华瑞铝业有限公司成立于2020年7月7日，位于重庆市綦江区古南街道桥河工业层区A区，隶属重庆一小时经济圈范围内，交通便利，物流发达。</p>
            <p style="text-indent: 2em;">
公司主要生产建筑铝幕墙、建筑新型装饰材料。涵盖了氟碳铝单板、冲孔铝单板、铝制蜂窝板、双曲面板、铝制天花板、保温隔热板、仿木纹转印铝单板、仿真石漆、仿大理石铝单板等建筑装饰材料的生产制造。
</p><p style="text-indent: 2em;">
公司成立以来始终坚持以人为本，诚信经营，和谐发展，步步为营的发展理念。荟萃行业精莫，打造有组织、有纪律、有技术、不断学习、不断提高管理水平的优良团队，培养有梦想、想做事、能做事、做成事的实干型人才。坚持凡是工作必有目标，凡是目标必有计划，凡是计划必有执行，凡是执行必有检查，凡是检查必有结果，凡是结果必有责任，凡是责任必有奖惩的举措。
          </p>
        </div>
        <img src="../assets/qyjj.jpg" style="height: min-content;" />

      </a-col>
      <!-- 关于我们 -->
      <a-col :span="8" class="ind_about">
        <div class="aabb">
          <h1 class="c_about">关于我们<p style="font-size: medium;">ABOUT</p></h1>
          <button style="background-color: rgb(34,34,34);margin-bottom: 5px;">
            <router-link to="/about"> <span style="color: white;">查看详情 ></span></router-link>
          </button>
        </div>
      </a-col>
      <a-col :span="16"  class="ind_about_a">
        <h1 style="color: #fff;">重庆华瑞铝业有限公司</h1>
        <p>
          <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;重庆华瑞铝业有限公司成立于</span>
          <span>2020年7月7日，位于重庆市綦江区古南街道桥河工业园区A区，隶属重庆一小时经济圈范围内，交通便利，物流发达。</span>
          </span>
        </p>
      </a-col>
      <!-- 工程案例 -->
        <a-col :span="24" style="text-align: center;background-color: #2b378b;">
          <h1 style="color: #fff;">
          经典案例
          </h1>
          <h2 style="color: #fff;">successful case</h2>
        </a-col>
        <a-col :span="4" style="background-color: #2b378b;"/>
        <a-col :span="8" style="background-color: #2b378b;">
          <a-card hoverable style="width: 80%;">
            <template #cover>
              <img alt="example" src="../assets/djhis.png" />
            </template>
            <a-card-meta title="垫江人民医院">
              <template #description>dianjiang people's hospital</template>
            </a-card-meta>
          </a-card>
          <a-card hoverable style="width: 80%;">
            <template #cover>
              <img alt="example" src="../assets/tlsfz.jpg" style="height: 180px;"/>
            </template>
            <a-card-meta title="潼南北收费站">
              <template #description>Tollgate north and south of Tong</template>
            </a-card-meta>
          </a-card>
          <div style="margin-top: 10%;margin-left: 20%;"><router-link to="/case">查看更多<p style="color: rgb(24, 144, 255);">MORE</p></router-link></div>
        </a-col>
        <a-col :span="8" style="background-color: #2b378b;">
          <img alt="Vue logo" src="../assets/qiyewenhua.jpg" style="width: 100%;height: 100%;">
        </a-col>
        <a-col :span="4" style="background-color: #2b378b;" />

    </a-row>
  </div>
 
  </div>
</template>

<script>
// import { Carousel } from 'ant-design-vue';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons-vue';
// import AboutViewVue from './AboutView.vue';
export default {
  name: 'HomeView',
  components: {
    // 'a-carousel': Carousel,
    LeftCircleOutlined,
    RightCircleOutlined,
    // RightOutlined,
    // AboutViewVue,
  },
  methods:{
    showTextAndButton(){
      document.querySelector('.text-and-button').style.display = 'block';

    },
    hideTextAndButton(){
      document.querySelector('.text-and-button').style.display = 'none';

    }
  }
}
</script>
<style scoped>
/* For demo */
.aabb{
  max-width:fit-content;
  margin: 0 auto;
  text-align:left;
  /* padding-top: 5px; */

}
.ind_about{
  background-color: rgba(60, 60, 60);
  color: #fff;
  text-align: center;
}
.c_about{
  margin-top: 10px;
  color: #fff;
}
.ind_about_a{
  background-color: rgba(97, 97, 97);
  color: #fff;
  text-align: center;
}
.ant-carousel :deep(.slick-slide) {
  text-align: center;
  height: 100%;
  line-height: 160px;
  /* background: #364d79; */
  overflow: hidden;
}

.ant-carousel :deep(.slick-arrow.custom-slick-arrow) {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #2f22a3;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
  z-index: 1;
}
.ant-carousel :deep(.custom-slick-arrow:before) {
  display: none;
}
.ant-carousel :deep(.custom-slick-arrow:hover) {
  opacity: 0.5;
}

.ant-carousel :deep(.slick-slide h3) {
  color: #1f178f;
}
div img{
  margin: 0 auto;  
  width: 80%; height: 60%;
  object-fit: cover;

}
hr{border: 5px solid #fff; width: 10%; float: left;}
p{
  color: #fff;
}
</style>