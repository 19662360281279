import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import BaiduMap from 'vue-baidu-map-3x';


createApp(App).use(store).use(router).use(Antd).use(BaiduMap,{ak:'S3EgUdexY0tWUkM6uzT2Ok7d0bqGN2r0'}).mount('#app')
